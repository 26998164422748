@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Montserrat', sans-serif;
}

:root {
    --gold-color: #98794a;
    --text-gold: #96784a;
}

.bg-gold {
    background-color: var(--gold-color) !important;
}

.text-gold {
    color: var(--text-gold);
}

.page-section {
    padding-top: 8rem;
    padding-bottom: 7rem;
}

.sticky-button {
    background: #000 no-repeat 88% center;
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    padding: 0 10px;
    outline: none;
    text-decoration: none;
    position: fixed;
    top: 109px;
    right: 0;
    /*width: 136px;*/
    height: 40px;
    line-height: 45px;
    transform: rotate(-90deg);

    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.sticky-button:hover {
    color: #f4623a;
}


/* Blockquote */
.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote > :last-child {
    margin-bottom: 0;
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.875em;
    color: #6c757d;
}

.blockquote-footer::before {
    content: "— ";
}

blockquote {
    font-size: 1.1em;
    line-height: 1.6em;
    padding: 20px 20px 20px 40px;
    border-left: none;
    position: relative;
    text-indent: -19px
}

blockquote p {
    font-size: 1em
}

blockquote:before,
blockquote:after {
    font-size: 2.5em;
    vertical-align: middle;
    line-height: 0
}

blockquote:before {
    content: open-quote;
    margin-right: 4px
}

blockquote:after {
    content: close-quote;
    margin-left: 3px
}

blockquote.blockstyle,
blockquote.style2 {
    background: #fff;
    font-style: italic
}

blockquote.blockstyle p,
blockquote.style2 p {
    display: inline
}

blockquote.blockstyle:before,
blockquote.blockstyle:after {
    color: #96784a
}

blockquote.blockstyle.border-color-blue {
    border-color: #96784a
}

blockquote.blockstyle.border-color-blue > span.triangle:before {
    color: #96784a
}

blockquote.blockstyle.border-color-yellow {
    border-color: #fdb714
}

blockquote.blockstyle.border-color-yellow > span.triangle:before {
    color: #fdb714
}

/* Blockquote stop */

hr.divider {
    height: 0.2rem;
    max-width: 3.25rem;
    margin: 1.5rem auto;
    background-color: #97784b;
    opacity: 1;
}

@keyframes spinning {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}
.spin {
    animation-name: spinning;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    /* linear | ease | ease-in | ease-out | ease-in-out */
    animation-timing-function: linear;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.custom-button {
    font-weight: bold;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    min-width: 10rem;
    border: 0.15rem solid #98794a;
    transition-property: color, background-color;
    transition: 0.7s ease-out;
    color: #98794a;
    font-size: 20px;
    background: #ffffff;
    text-decoration: none;
}

.custom-button:hover {
    color: white;
    background: #98794a;
    background-image: -webkit-linear-gradient(top, #98794a, #98794a);
    background-image: -moz-linear-gradient(top, #98794a, #98794a);
    background-image: -ms-linear-gradient(top, #98794a, #98794a);
    background-image: -o-linear-gradient(top, #98794a, #98794a);
    background-image: linear-gradient(to bottom, #98794a, #98794a);
    text-decoration: none;
}

.map {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}

.parallax-one {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-image: url(https://images.unsplash.com/photo-1415018255745-0ec3f7aee47b?dpr=1&auto=format&fit=crop&w=1500&h=938&q=80&cs=tinysrgb&crop=);
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}
#parallax-world-of-ugg .parallax-two {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-image: url(https://images.unsplash.com/photo-1432163230927-a32e4fd5a326?dpr=1&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=);
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
#parallax-world-of-ugg .parallax-three {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-image: url(https://images.unsplash.com/photo-1440688807730-73e4e2169fb8?dpr=1&auto=format&fit=crop&w=1500&h=1001&q=80&cs=tinysrgb&crop=);
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
